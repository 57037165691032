import { useEffect, useState } from 'react';
import {
  createStyles,
  Header,
  Container,
  Group,
  Burger,
  Paper,
  Transition,
  rem,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import React from 'react';

const HEADER_HEIGHT = '80px';

const useStyles = createStyles((theme) => ({
  root: {
    width: '100%',
    position: 'fixed',
    zIndex: 100,
    backgroundColor: 'transparent',
    borderBottom: 0,
  },

  background: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: theme.colors.gray[1],
    opacity: 0,
    transition: 'opacity 0.2s ease-in-out',

    '&.scrolled': {
      opacity: 1,

      borderBottom: '1px solid #ccc',
    },
  },

  dropdown: {
    position: 'fixed',
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    zIndex: 101,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: 'hidden',

    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  header: {
    position: 'relative',
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    height: '100%',
  },

  links: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  burger: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  link: {
    display: 'block',
    lineHeight: 1,
    padding: `${rem(8)} ${rem(12)}`,
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[0],
    fontSize: theme.fontSizes.md,
    fontWeight: 700,
    letterSpacing: '1px',
    '&.scrolled': {
      color: theme.colors.gray[8],
      '&:hover': {
        color: theme.colors.blue[7],
      },
    },

    '&:hover': {
      // backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[9],
      color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[0],

      [theme.fn.smallerThan('sm')]: {
        color: theme.colors.blue[6],
      },
    },

    [theme.fn.smallerThan('sm')]: {
      borderRadius: 0,
      padding: theme.spacing.md,
      color: theme.colors.gray[9],
      fontSize: theme.fontSizes.sm,
    },
  },

  linkActive: {
    '&, &:hover': {
      // backgroundColor: theme.fn.variant({ variant: 'light', color: 'red' }).background,
      color: theme.colors.gray[0],
    },

    '&.scrolled': {
      // backgroundColor: theme.fn.variant({ variant: 'light', color: 'red' }).background,
      color: theme.colors.gray[7],
    },

    [theme.fn.smallerThan('sm')]: {
      color: theme.colors.blue[6],
    },
  },
}));

export interface LinkItem {
  link: string;
  label: string;
}

export interface PageHeaderLinks {
  links: LinkItem[];
}

export const PageHeader: React.FC<PageHeaderLinks> = ({ links }) => {
  const [opened, { toggle, close }] = useDisclosure(false);
  const [active, setActive] = useState(links[0].link);

  const { classes, cx } = useStyles();

  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    const scrolled = window.scrollY > 20;
    setIsScrolled(scrolled);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const items = links.map((link) => (
    <a
      key={link.label}
      href={link.link}
      className={cx(classes.link, {
        [classes.linkActive]: active === link.label,
        ['scrolled']: isScrolled,
      })}
      onClick={(event) => {
        setActive(link.label);
        close();
      }}>
      {link.label}
    </a>
  ));

  const imgSrc = isScrolled ? '/grantme_dark_transparent.png' : '/grantme_light_transparent.png';

  return (
    <Header height={HEADER_HEIGHT} className={classes.root}>
      <div className={cx(classes.background, { ['scrolled']: isScrolled })} />
      <Container size={'md'} className={classes.header} pr={'40px'}>
        <Group position="apart" w={'100%'}>
          <img src={imgSrc} height={35} />
          <Group spacing={5} className={classes.links}>
            {items}
          </Group>
        </Group>

        <Burger
          color={isScrolled ? 'black' : 'white'}
          opened={opened}
          onClick={toggle}
          className={classes.burger}
          size="md"
        />

        <Transition transition="pop-top-right" duration={200} mounted={opened}>
          {(styles) => (
            <Paper className={classes.dropdown} withBorder style={styles}>
              {items}
            </Paper>
          )}
        </Transition>
      </Container>
    </Header>
  );
};
